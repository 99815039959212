import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { SideNav as ReusableSideNav } from '@goodfynd/react-web.layout.side-nav';

import config from '../../../config';
import { useApp, useAppDispatch } from '../../../context/AppContext';
import { useEvent } from '../../../context/EventContext';
import { ProfileSwitcher } from '../../events/ProfileSwitcher';
import type { ProfileStatus } from '@goodfynd/react-web.dashboard.profile-switcher';
import { SIDE_NAV } from './side-nav-links';

export default observer(function SideNav() {
  const { isLoggedIn } = useApp();
  const { logout } = useAppDispatch();
  const { eventItem } = useEvent();

  const navItems = SIDE_NAV(eventItem.nameId);

  const status: ProfileStatus | null = useMemo(() => {
    if (eventItem?.isDeleted) {
      return 'no-profiles';
    }
    return 'online';
  }, [eventItem]);

  return (
    <ReusableSideNav
      header={
        <ProfileSwitcher css={{ width: 'fit-content' }} status={status} />
      }
      items={navItems}
      logoEnvironment={config.env.NAME_LOWER}
      logout={
        isLoggedIn
          ? () => {
              logout();
            }
          : undefined
      }
      logoType="events"
    />
  );
});
