import { useMemo } from 'react';
import * as stringUtil from '@goodfynd/react-web.utils.string-util';

import endpoints from '../../services/api/endpoints';
import fetch from '../../services/api/authenticated-fetch-wrapper';
import type { LotProfile } from '../../types/lots';

export const useProfileApi = () => {
  const api = fetch();
  return useMemo(() => {
    return {
      async lotProfile(id: string): Promise<LotProfile> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.profiles.lot, { ':id': id })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
