import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useRef } from 'react';

import { ProfileSwitcher as ProfileSwitcherReusable } from '@goodfynd/react-web.dashboard.profile-switcher';

import env from '../../../config/env';
import routes from '../../../config/routes';
import { useEvent } from '../../../context/EventContext';
import { useOrganization } from '../../../context/OrganizationContext';
import { getLotName } from '../../../utils/event-util';
import {
  getDashboardRoutePath,
  getOrganizationRoutePath,
} from '../../../utils/routes-util';
import { AutocompleteEventOrganizations } from '../AutocompleteEventOrganizations';

import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type {
  ProfileSwitcherMethods,
  ProfileSwitcherProps as ProfileSwitcherReusableProps,
} from '@goodfynd/react-web.dashboard.profile-switcher';
export default observer(function ProfileSwitcher({
  isOrganization,
  ...props
}: Partial<ProfileSwitcherReusableProps>) {
  const ref = useRef<ProfileSwitcherMethods>(null);

  const { push } = useRouter();
  const { isLoading: orgIsLoading, organization } = useOrganization();
  const { isLoading: eventIsLoading, eventItem } = useEvent();
  const isLoading = useMemo(
    () => eventIsLoading || orgIsLoading,
    [eventIsLoading, orgIsLoading]
  );

  const profileName = useMemo(
    () => (isOrganization ? organization.name : getLotName(eventItem)),
    [eventItem, isOrganization, organization.name]
  );

  const status = useMemo(() => {
    if (isOrganization) {
      return 'organization';
    }

    return undefined;
  }, [isOrganization]);

  const handleAddNew = useCallback(() => {
    push(getDashboardRoutePath(routes.dashboard.add, eventItem.nameId));
  }, [eventItem.nameId, push]);

  const handleSelect = useCallback(
    (id, type) => {
      switch (type) {
        case 'lot':
          push(getDashboardRoutePath(routes.dashboard.events.home, id));
          break;

        case 'organization':
          push(getOrganizationRoutePath(routes.organization.overview, id));
          break;

        case 'vendor':
          window.open(`${env.VENDOR_APP_HOST}/dashboard/${id}`);
          break;

        default:
          break;
      }
    },
    [push]
  );

  return (
    <ProfileSwitcherReusable
      addButtonLabel="Add event"
      autocompleteComponent={
        <AutocompleteEventOrganizations
          css={{ paddingBottom: 0, paddingTop: 24 }}
          onChange={(option: FilterMenuOption) => {
            handleSelect(option.value, option.type);
            ref.current?.toggleModal?.(false);
          }}
        />
      }
      icon="lot"
      isLoading={isLoading}
      isOrganization={isOrganization}
      onAdd={handleAddNew}
      onSelect={handleSelect}
      profileName={profileName}
      ref={ref}
      status={status}
      {...props}
    />
  );
});
